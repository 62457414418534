/**-----------------------------------------------------------------------------------------
* Copyright © 2020 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata, __param } from 'tslib';
import { HostBinding, Input, Component, Renderer2, ElementRef, NgModule, Directive, Optional, TemplateRef, EventEmitter, Output, ViewChildren, QueryList, NgZone, isDevMode, ViewChild, ContentChild, ChangeDetectorRef } from '@angular/core';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { validatePackage } from '@progress/kendo-licensing';
import { CommonModule } from '@angular/common';
import { isDocumentAvailable, Keys, ResizeSensorComponent, ResizeSensorModule } from '@progress/kendo-angular-common';
import { fromEvent, merge, ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter, map, startWith, share } from 'rxjs/operators';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-navigation',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1620632789,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

/**
 * Represents the [Kendo UI AppBar component for Angular]({% slug overview_appbar %}).
 * Used to display information, actions, branding titles and additional navigation on the current screen.
 */
let AppBarComponent = class AppBarComponent {
    constructor(localizationService) {
        this.localizationService = localizationService;
        this.hostClass = true;
        /**
         * Specifies the position of the AppBar
         * ([see example]({% slug positioning_appbar %}#toc-position)).
         *
         * * The possible values are:
         * * `top` (Default)&mdash;Positions the AppBar at the top of the content.
         *    Setting the `position` property to `top` requires adding the Appbar component before the page content.
         *    The position property applies CSS `top: 0` style in [`fixed mode`]({% slug api_navigation_appbarcomponent %}#toc-positionMode) and also adds a `box-shadow` to the bottom of the AppBar.
         *
         * * `bottom`&mdash;Positions the AppBar at the bottom of the content.
         *    Setting the `position` property to `bottom` requires adding the Appbar component after the page content.
         *   The position property applies CSS `bottom: 0` style in [`fixed mode`]({% slug api_navigation_appbarcomponent %}#toc-positionMode) and also adds a `box-shadow ` to the top of the AppBar.
         *
         */
        this.position = 'top';
        /**
         * Specifies the positionMode of the AppBar
         * ([see example]({% slug positioning_appbar %}#toc-positionmode)).
         *
         * * The possible values are:
         * * `static` (Default)&mdash;Does not position the AppBar in any special way. It is positioned according to the normal flow of the page.
         * * `sticky`&mdash;Positions the AppBar based on the user's scroll position. A sticky element toggles between static and fixed CSS [`position`](https://developer.mozilla.org/en-US/docs/Web/CSS/position) property, depending on the scroll position.
         * * `fixed`&mdash;Positions the AppBar relative to the viewport. It always stays in the same place even if the page is scrolled.
         */
        this.positionMode = 'static';
        /**
         * Specifies the theme color of the AppBar.
         * The theme color will be applied as background color of the component.
         *
         *
         * * The possible values are:
         * * `light` (Default)&mdash;Applies coloring based on light theme color.
         * * `dark`&mdash;Applies coloring based on dark theme color.
         * * `inherit`&mdash; Applies inherited coloring value.
         *
         */
        this.themeColor = 'light';
        this.rtl = false;
        validatePackage(packageMetadata);
        this.dynamicRTLSubscription = this.localizationService.changes.subscribe(({ rtl }) => {
            this.rtl = rtl;
            this.direction = this.rtl ? 'rtl' : 'ltr';
        });
    }
    get topClass() {
        return this.position === 'top';
    }
    get bottomClass() {
        return this.position === 'bottom';
    }
    get stickyClass() {
        return this.positionMode === 'sticky';
    }
    get fixedClass() {
        return this.positionMode === 'fixed';
    }
    get staticClass() {
        return this.positionMode === 'static';
    }
    get lightClass() {
        return this.themeColor === 'light';
    }
    get darkClass() {
        return this.themeColor === 'dark';
    }
    get inheritClass() {
        return this.themeColor === 'inherit';
    }
    ngOnDestroy() {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
    }
};
__decorate([
    HostBinding('class.k-appbar'),
    __metadata("design:type", Boolean)
], AppBarComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('class.k-appbar-top'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], AppBarComponent.prototype, "topClass", null);
__decorate([
    HostBinding('class.k-appbar-bottom'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], AppBarComponent.prototype, "bottomClass", null);
__decorate([
    HostBinding('class.k-appbar-sticky'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], AppBarComponent.prototype, "stickyClass", null);
__decorate([
    HostBinding('class.k-appbar-fixed'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], AppBarComponent.prototype, "fixedClass", null);
__decorate([
    HostBinding('class.k-appbar-static'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], AppBarComponent.prototype, "staticClass", null);
__decorate([
    HostBinding('class.k-appbar-light'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], AppBarComponent.prototype, "lightClass", null);
__decorate([
    HostBinding('class.k-appbar-dark'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], AppBarComponent.prototype, "darkClass", null);
__decorate([
    HostBinding('class.k-appbar-inherit'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], AppBarComponent.prototype, "inheritClass", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], AppBarComponent.prototype, "direction", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], AppBarComponent.prototype, "position", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], AppBarComponent.prototype, "positionMode", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], AppBarComponent.prototype, "themeColor", void 0);
AppBarComponent = __decorate([
    Component({
        exportAs: 'kendoAppBar',
        selector: 'kendo-appbar',
        template: `
        <ng-content></ng-content>
    `,
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.appbar.component'
            }
        ]
    }),
    __metadata("design:paramtypes", [LocalizationService])
], AppBarComponent);

/**
 * Represents the [Kendo UI AppBarSection component for Angular]({% slug contentarrangement_appbar %}#toc-sections).
 *
 * @example
 *
 * ```ts-no-run
 *  * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-appbar>
 *            <kendo-appbar-section>
 *                <h2>Page Title</h2>
 *            </kendo-appbar-section>
 *        </kendo-appbar>
 *    `
 * })
 * class AppComponent {}
 */
let AppBarSectionComponent = class AppBarSectionComponent {
    /**
     * Represents the [Kendo UI AppBarSection component for Angular]({% slug contentarrangement_appbar %}#toc-sections).
     *
     * @example
     *
     * ```ts-no-run
     *  * _@Component({
     *    selector: 'my-app',
     *    template: `
     *        <kendo-appbar>
     *            <kendo-appbar-section>
     *                <h2>Page Title</h2>
     *            </kendo-appbar-section>
     *        </kendo-appbar>
     *    `
     * })
     * class AppComponent {}
     */
    constructor() {
        this.hostClass = true;
    }
};
__decorate([
    HostBinding('class.k-appbar-section'),
    __metadata("design:type", Boolean)
], AppBarSectionComponent.prototype, "hostClass", void 0);
AppBarSectionComponent = __decorate([
    Component({
        selector: 'kendo-appbar-section',
        template: `
        <ng-content></ng-content>
    `
    })
], AppBarSectionComponent);

/**
 * @hidden
 */
const isPresent = (value) => value !== null && value !== undefined;
/**
 * @hidden
 */
const outerWidth = (element) => {
    let width = element.offsetWidth;
    const style = getComputedStyle(element);
    width += (parseFloat(style.marginLeft) || 0) + (parseFloat(style.marginRight) || 0);
    return width;
};

/**
 * Represents the [Kendo UI AppBarSpacer component for Angular]({% slug contentarrangement_appbar %}#toc-spacings).
 * Used to give additional white space between the AppBar sections and provides a way for customizing its width.
 *
 * @example
 *
 * ```ts-no-run
 *  * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-appbar>
 *            <kendo-appbar-section>
 *                 <button class="k-button k-button-clear">
 *                     <kendo-icon [name]="'menu'"></kendo-icon>
 *                 </button>
 *            </kendo-appbar-section>
 *
 *            <kendo-appbar-spacer></kendo-appbar-spacer>
 *
 *            <kendo-appbar-section>
 *                <h2>Page Title</h2>
 *            </kendo-appbar-section>
 *        </kendo-appbar>
 *    `
 * })
 * class AppComponent {}
 * ```
 */
let AppBarSpacerComponent = class AppBarSpacerComponent {
    constructor(renderer, element) {
        this.renderer = renderer;
        this.element = element;
        this.hostClass = true;
    }
    get sizedClass() {
        return isPresent(this.width);
    }
    ngAfterViewInit() {
        if (isPresent(this.width)) {
            const element = this.element.nativeElement;
            this.renderer.setStyle(element, 'flexBasis', this.width);
        }
    }
};
__decorate([
    HostBinding('class.k-appbar-spacer'),
    __metadata("design:type", Boolean)
], AppBarSpacerComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('class.k-appbar-spacer-sized'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], AppBarSpacerComponent.prototype, "sizedClass", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], AppBarSpacerComponent.prototype, "width", void 0);
AppBarSpacerComponent = __decorate([
    Component({
        selector: 'kendo-appbar-spacer',
        template: ``
    }),
    __metadata("design:paramtypes", [Renderer2, ElementRef])
], AppBarSpacerComponent);

const exportedModules = [
    AppBarComponent,
    AppBarSectionComponent,
    AppBarSpacerComponent
];
const declarations = [
    ...exportedModules
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the AppBar component.
 *
 * * @example
 *
 * ```ts-no-run
 * // Import the AppBar module
 * import { AppBarModule } from '@progress/kendo-angular-navigation';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { BrowserModule } from '@angular/platform-browser';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, AppBarModule], // import AppBar module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let AppBarModule = class AppBarModule {
};
AppBarModule = __decorate([
    NgModule({
        declarations: [declarations],
        exports: [exportedModules],
        imports: [CommonModule]
    })
], AppBarModule);

/**
 * Represents a template that defines the content of a Breadcrumb item.
 * To define the template, nest an `<ng-template>` tag with the `kendoBreadCrumbItemTemplate` directive inside the `<kendo-breadcrumb>` tag.
 *
 * For more information and example refer to the [Templates]({% slug templates_breadcrumb %}) article.
 */
let BreadCrumbItemTemplateDirective = class BreadCrumbItemTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
BreadCrumbItemTemplateDirective = __decorate([
    Directive({
        selector: '[kendoBreadCrumbItemTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], BreadCrumbItemTemplateDirective);

/**
 * @hidden
 */
const BREADCRUMB_ITEM_INDEX = 'data-kendo-breadcrumb-index';

/**
 * @hidden
 */
let BreadCrumbItemComponent = class BreadCrumbItemComponent {
    constructor(el) {
        this.el = el;
        this.index = -1;
        this.hostClasses = true;
        this.disabled = false;
    }
    get isRootItem() {
        return this.item.context.isFirst;
    }
    get isDisabled() {
        return this.disabled || null;
    }
    get isLastItem() {
        return this.item.context.isLast;
    }
    ngOnInit() {
        this.disabled = this.item.data && (this.item.data.disabled || this.item.context.isLast);
    }
    ngAfterViewInit() {
        if (isDocumentAvailable()) {
            this.width = outerWidth(this.el.nativeElement);
        }
    }
    onImageLoad() {
        if (isDocumentAvailable()) {
            this.width = outerWidth(this.el.nativeElement);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], BreadCrumbItemComponent.prototype, "item", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], BreadCrumbItemComponent.prototype, "collapseMode", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], BreadCrumbItemComponent.prototype, "index", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], BreadCrumbItemComponent.prototype, "itemTemplate", void 0);
__decorate([
    HostBinding('class.k-flex-none'),
    HostBinding('class.k-breadcrumb-item'),
    __metadata("design:type", Boolean)
], BreadCrumbItemComponent.prototype, "hostClasses", void 0);
__decorate([
    HostBinding('class.k-breadcrumb-root-item'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], BreadCrumbItemComponent.prototype, "isRootItem", null);
__decorate([
    HostBinding('attr.aria-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], BreadCrumbItemComponent.prototype, "isDisabled", null);
__decorate([
    HostBinding('class.k-breadcrumb-last-item'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], BreadCrumbItemComponent.prototype, "isLastItem", null);
BreadCrumbItemComponent = __decorate([
    Component({
        selector: '[kendoBreadCrumbItem]',
        template: `
        <ng-template #separator>
            <ng-content select="[kendoBreadCrumbSeparator]"></ng-content>
        </ng-template>

        <ng-container *ngIf="collapseMode === 'wrap'">
            <ng-container *ngTemplateOutlet="separator"></ng-container>
        </ng-container>

        <ng-container *ngIf="!item.context.collapsed">
            <span
                *ngIf="!itemTemplate"
                [ngClass]="{
                    'k-breadcrumb-root-link': item.context.isFirst,
                    'k-breadcrumb-link': index !== 0,
                    'k-breadcrumb-icontext-link': !!item.data.icon && !!item.data.text,
                    'k-breadcrumb-icon-link': !!item.data.icon && !item.data.text,
                    'k-state-disabled': disabled
                }"
                [title]="item.data.title || ''"
                [tabindex]="disabled ? -1 : 0"
                [attr.aria-current]="item.context.isLast ? true : null"
                role="link"
                class="k-cursor-pointer k-flex-none"
            >
                <img *ngIf="item.data.imageUrl" (load)="onImageLoad()" [src]="item.data.imageUrl" class="k-image" role="presentation" />
                <span *ngIf="item.data.icon && !item.data.iconClass" class="k-icon k-i-{{ item.data.icon }}"></span>
                <span *ngIf="item.data.iconClass" class="{{ item.data.iconClass }}"></span>
                {{ item.data.text }}
            </span>
            <ng-template
                *ngIf="itemTemplate"
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{
                    $implicit: item.data,
                    index: index
                }"
            ></ng-template>
        </ng-container>

        <ng-container *ngIf="collapseMode !== 'wrap'">
            <ng-container *ngTemplateOutlet="separator"></ng-container>
        </ng-container>
    `
    }),
    __metadata("design:paramtypes", [ElementRef])
], BreadCrumbItemComponent);

const closestInScope = (target, targetAttr, predicate, scope) => {
    while (target && target !== scope && !predicate(target, targetAttr)) {
        target = target.parentNode;
    }
    if (target !== scope) {
        return target;
    }
};
const hasItemIndex = (item, indexAttr) => isPresent(item.getAttribute(indexAttr));
/**
 * @hidden
 */
const itemIndex = (item, indexAttr) => +item.getAttribute(indexAttr);
/**
 * @hidden
 */
const closestItem = (target, targetAttr, scope) => closestInScope(target, targetAttr, hasItemIndex, scope);

/**
 * @hidden
 */
let BreadCrumbListComponent = class BreadCrumbListComponent {
    constructor(el, zone) {
        this.el = el;
        this.zone = zone;
        this.items = [];
        this.collapseMode = 'auto';
        this.itemClick = new EventEmitter();
        const element = this.el.nativeElement;
        this.isRootItemContainer = element.classList.contains('k-breadcrumb-root-item-container');
        this.zone.runOutsideAngular(() => {
            const click$ = fromEvent(element, 'click');
            const enterKey$ = fromEvent(element, 'keydown').pipe(filter((ev /* KeyboardEvent causes lint error */) => ev.keyCode === Keys.Enter));
            this.domEventsSubscription = merge(click$, enterKey$)
                .pipe(map((ev) => ev.target), filter(e => !e.classList.contains('k-breadcrumb-delimiter-icon')), // do not trigger handler when a separator is clicked
            map(e => this.getItemIndex(e)), filter(isPresent), map(i => parseInt(i, 10)), map(i => this.items[i]), filter(item => !item.data.disabled && !item.context.isLast), map(item => item.data))
                .subscribe(el => {
                this.zone.run(() => this.itemClick.emit(el));
            });
        });
    }
    ngOnDestroy() {
        this.domEventsSubscription.unsubscribe();
    }
    getItemIndex(target) {
        const item = closestItem(target, BREADCRUMB_ITEM_INDEX, this.el.nativeElement);
        if (item) {
            return itemIndex(item, BREADCRUMB_ITEM_INDEX);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], BreadCrumbListComponent.prototype, "items", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], BreadCrumbListComponent.prototype, "itemTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], BreadCrumbListComponent.prototype, "collapseMode", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], BreadCrumbListComponent.prototype, "separatorIcon", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], BreadCrumbListComponent.prototype, "itemClick", void 0);
__decorate([
    ViewChildren(BreadCrumbItemComponent, { static: true }),
    __metadata("design:type", QueryList)
], BreadCrumbListComponent.prototype, "renderedItems", void 0);
BreadCrumbListComponent = __decorate([
    Component({
        selector: '[kendoBreadCrumbList]',
        template: `
        <ng-container *ngFor="let item of items; let i = index; let isFirst = first; let isLast = last">
            <li
                #renderedItem
                kendoBreadCrumbItem
                *ngIf="!(collapseMode === 'wrap' && isFirst) || isRootItemContainer"
                [attr.${BREADCRUMB_ITEM_INDEX}]="i"
                [item]="item"
                [index]="i"
                [collapseMode]="collapseMode"
                [itemTemplate]="itemTemplate"
            >
                <span kendoBreadCrumbSeparator [icon]="separatorIcon" *ngIf="collapseMode === 'wrap' && !isFirst"></span>
                <span
                    kendoBreadCrumbSeparator
                    [icon]="separatorIcon"
                    *ngIf="collapseMode !== 'wrap' && !isLast && !(item?.context.collapsed && items[i + 1]?.context.collapsed)"
                ></span>
            </li>
        </ng-container>
    `
    }),
    __metadata("design:paramtypes", [ElementRef, NgZone])
], BreadCrumbListComponent);

const getCollapsed = (itemComponent) => itemComponent.item.context.collapsed;
/**
 * @hidden
 */
const collapsed = (itemComponent) => getCollapsed(itemComponent) === true;
/**
 * @hidden
 */
const expanded = (itemComponent) => getCollapsed(itemComponent) === false;
const toggleFirst = (collapsed) => (itemComponents) => (itemComponents.find(ic => getCollapsed(ic) === collapsed).item.context.collapsed = !collapsed);
/**
 * @hidden
 */
const collapseFirst = toggleFirst(false);
/**
 * @hidden
 */
const expandFirst = toggleFirst(true);

/**
 * Represents the [Kendo UI Breadcrumb component for Angular]({% slug overview_breadcrumb %}).
 *
 * @example
 * ```ts-no-run
 * _@Component({
 *      selector: 'my-app',
 *      template: `
 *          <kendo-breadcrumb
 *              [items]="items"
 *              (itemClick)="onItemClick($event)">
 *          </kendo-breadcrumb>
 *      `
 * })
 * class AppComponent {
 *      public items: BreadCrumbItem[] = [
 *          { text: 'Home', title: 'Home', icon: 'home' },
 *          { text: 'Kids', title: 'Kids' },
 *          { text: '8y-16y', title: '8y-16y', disabled: true },
 *          { text: 'New collection', title: 'New collection' },
 *          { text: 'Jeans', title: 'Jeans' }
 *      ];
 *
 *      public onItemClick(item: BreadCrumbItem): void {
 *          console.log(item);
 *      }
 * }
 * ```
 */
let BreadCrumbComponent = class BreadCrumbComponent {
    constructor(el, cdr, zone, localization) {
        this.el = el;
        this.cdr = cdr;
        this.zone = zone;
        this.localization = localization;
        /**
         * Fires when a Breadcrumb item is clicked. The event will not be fired by disabled items and the last item.
         */
        this.itemClick = new EventEmitter();
        this.hostClasses = true;
        this._items = [];
        this._collapseMode = 'auto';
        this.updateItems = new ReplaySubject();
        this.afterViewInit = new Subject();
        this.subscriptions = new Subscription();
        this.direction = 'ltr';
        validatePackage(packageMetadata);
        const updateItems$ = this.updateItems.asObservable().pipe(startWith([]));
        this.direction = localization.rtl ? 'rtl' : 'ltr';
        this.itemsData$ = updateItems$.pipe(map(items => items.filter(Boolean)), map(items => items.map((item, index, collection) => ({
            context: {
                collapsed: false,
                isLast: index === collection.length - 1,
                isFirst: index === 0
            },
            data: item
        }))), share());
        this.firstItem$ = updateItems$.pipe(map(items => {
            if (items.length > 0) {
                return [
                    {
                        context: {
                            collapsed: false,
                            isLast: items.length === 1,
                            isFirst: true
                        },
                        data: items[0]
                    }
                ];
            }
            return [];
        }), share());
    }
    /**
     * The collection of items that will be rendered in the Breadcrumb.
     */
    set items(items) {
        this._items = items || [];
        this.updateItems.next(this._items);
    }
    get items() {
        return this._items;
    }
    /**
     * Specifies the collapse mode of the Breadcrumb ([see example]({% slug collapse_modes_breadcrumb %})).
     *
     * The possible values are:
     * - `auto` (default)&mdash;items are automatically collapsed based on the width of the Breadcrumb.
     * - `wrap`&mdash;items are wrapped on multiple rows.
     * - `none`&mdash;all items are expanded on the same row.
     *
     * For more information and example refer to the [Collapse Modes]({% slug collapse_modes_breadcrumb %}) article.
     */
    set collapseMode(mode) {
        if (isDevMode() && ['auto', 'wrap', 'none'].indexOf(mode) < 0) {
            throw new Error('Invalid collapse mode. Allowed values are "auto", "wrap" or "none". \nFor more details see https://www.telerik.com/kendo-angular-ui/components/navigation/api/BreadCrumbCollapseMode/');
        }
        this._collapseMode = mode || 'auto';
        this.updateItems.next(this.items);
    }
    get collapseMode() {
        return this._collapseMode;
    }
    get wrapMode() {
        return this.collapseMode === 'wrap';
    }
    get getDir() {
        return this.direction;
    }
    ngOnInit() {
        this.subscriptions.add(this.localization.changes.subscribe(({ rtl }) => (this.direction = rtl ? 'rtl' : 'ltr')));
    }
    ngAfterViewInit() {
        this.attachResizeHandler();
        this.afterViewInit.next();
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
    handleResize() {
        const autoCollapseCandidates = [
            ...this.listComponent.renderedItems.toArray().filter(ri => !ri.item.context.isFirst && !ri.item.context.isLast)
        ];
        const componentWidth = outerWidth(this.el.nativeElement);
        const itemsContainerWidth = Math.floor(this.itemsContainers
            .toArray()
            .map(el => outerWidth(el.nativeElement))
            .reduce((acc, curr) => acc + curr, 0));
        const nextExpandWidth = ([...autoCollapseCandidates].reverse().find(collapsed) || { width: 0 }).width;
        // // shrink
        if (componentWidth <= itemsContainerWidth && autoCollapseCandidates.find(expanded)) {
            collapseFirst(autoCollapseCandidates);
            // needed by resize sensor
            this.cdr.detectChanges();
            return this.handleResize();
        }
        // expand
        if (componentWidth > itemsContainerWidth + nextExpandWidth && autoCollapseCandidates.find(collapsed)) {
            expandFirst([...autoCollapseCandidates].reverse());
            // needed by resize sensor
            this.cdr.detectChanges();
            return this.handleResize();
        }
    }
    shouldResize() {
        return isDocumentAvailable() && this.collapseMode === 'auto';
    }
    attachResizeHandler() {
        // resize when:
        // the component is initialized
        // the container is resized
        // items are added/removed
        this.subscriptions.add(merge(this.resizeSensor.resize, this.itemsData$, this.afterViewInit.asObservable())
            .pipe(filter(() => this.shouldResize()))
            .subscribe(() => {
            this.zone.runOutsideAngular(() => setTimeout(() => {
                this.zone.run(() => {
                    if (this.listComponent) {
                        this.handleResize();
                        this.resizeSensor.acceptSize();
                    }
                });
            }));
        }));
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], BreadCrumbComponent.prototype, "items", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], BreadCrumbComponent.prototype, "separatorIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], BreadCrumbComponent.prototype, "collapseMode", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], BreadCrumbComponent.prototype, "itemClick", void 0);
__decorate([
    ViewChild('resizeSensor', { static: true }),
    __metadata("design:type", ResizeSensorComponent)
], BreadCrumbComponent.prototype, "resizeSensor", void 0);
__decorate([
    ViewChildren('itemsContainer', { read: ElementRef }),
    __metadata("design:type", QueryList)
], BreadCrumbComponent.prototype, "itemsContainers", void 0);
__decorate([
    ViewChild(BreadCrumbListComponent, { static: true }),
    __metadata("design:type", BreadCrumbListComponent)
], BreadCrumbComponent.prototype, "listComponent", void 0);
__decorate([
    ContentChild(BreadCrumbItemTemplateDirective, { static: true }),
    __metadata("design:type", BreadCrumbItemTemplateDirective)
], BreadCrumbComponent.prototype, "itemTemplate", void 0);
__decorate([
    HostBinding('class.k-widget'),
    HostBinding('class.k-breadcrumb'),
    __metadata("design:type", Boolean)
], BreadCrumbComponent.prototype, "hostClasses", void 0);
__decorate([
    HostBinding('class.k-breadcrumb-wrap'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], BreadCrumbComponent.prototype, "wrapMode", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], BreadCrumbComponent.prototype, "getDir", null);
BreadCrumbComponent = __decorate([
    Component({
        exportAs: 'kendoBreadCrumb',
        selector: 'kendo-breadcrumb',
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.breadcrumb'
            }
        ],
        template: `
        <ol
            #itemsContainer
            kendoBreadCrumbList
            class="k-breadcrumb-root-item-container"
            *ngIf="collapseMode === 'wrap'"
            [items]="firstItem$ | async"
            [itemTemplate]="itemTemplate?.templateRef"
            [collapseMode]="collapseMode"
            [separatorIcon]="separatorIcon"
            (itemClick)="itemClick.emit($event)"
        ></ol>
        <ol
            #itemsContainer
            kendoBreadCrumbList
            class="k-breadcrumb-container"
            [items]="itemsData$ | async"
            [itemTemplate]="itemTemplate?.templateRef"
            [collapseMode]="collapseMode"
            [separatorIcon]="separatorIcon"
            (itemClick)="itemClick.emit($event)"
            [ngClass]="{ 'k-flex-wrap': collapseMode === 'wrap', 'k-flex-none': collapseMode === 'none' }"
        ></ol>
        <kendo-resize-sensor [rateLimit]="1000" #resizeSensor></kendo-resize-sensor>
    `
    }),
    __metadata("design:paramtypes", [ElementRef,
        ChangeDetectorRef,
        NgZone,
        LocalizationService])
], BreadCrumbComponent);

const DEFAULT_ICON = 'arrow-chevron-right';
const DEFAULT_RTL_ICON = 'arrow-chevron-left';
/**
 * @hidden
 */
let BreadCrumbSeparatorDirective = class BreadCrumbSeparatorDirective {
    constructor(el, localization) {
        this.el = el;
        this.localization = localization;
        this.defaultClasses = true;
        this.ariaHidden = true;
        this.direction = 'ltr';
        this.direction = this.localization.rtl ? 'rtl' : 'ltr';
    }
    set icon(icon) {
        const element = this.el.nativeElement;
        element.classList.remove(`k-i-${this._icon}`);
        if (isPresent(icon)) {
            this._icon = icon;
            this.hasDefaultIcon = false;
        }
        else {
            this._icon = this.direction === 'ltr' ? DEFAULT_ICON : DEFAULT_RTL_ICON;
            this.hasDefaultIcon = true;
        }
        element.classList.add(`k-i-${this._icon}`);
    }
    get icon() {
        return this._icon;
    }
    ngOnInit() {
        this.localizationChangesSubscription = this.localization.changes.subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
            if (this.hasDefaultIcon) {
                this.icon = undefined;
            }
        });
    }
    ngOnDestroy() {
        this.localizationChangesSubscription.unsubscribe();
    }
};
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], BreadCrumbSeparatorDirective.prototype, "icon", null);
__decorate([
    HostBinding('class.k-breadcrumb-delimiter-icon'),
    HostBinding('class.k-icon'),
    __metadata("design:type", Boolean)
], BreadCrumbSeparatorDirective.prototype, "defaultClasses", void 0);
__decorate([
    HostBinding('attr.aria-hidden'),
    __metadata("design:type", Boolean)
], BreadCrumbSeparatorDirective.prototype, "ariaHidden", void 0);
BreadCrumbSeparatorDirective = __decorate([
    Directive({
        selector: '[kendoBreadCrumbSeparator]'
    }),
    __metadata("design:paramtypes", [ElementRef, LocalizationService])
], BreadCrumbSeparatorDirective);

const templateDirectives = [
    BreadCrumbItemTemplateDirective
];
const exportedModules$1 = [
    BreadCrumbComponent,
    BreadCrumbItemComponent,
    BreadCrumbListComponent,
    ...templateDirectives
];
const declarations$1 = [
    ...exportedModules$1,
    BreadCrumbSeparatorDirective
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the BreadCrumb component.
 * @example
 *
 * ```ts-no-run
 * // Import the BreadCrumb module
 * import { BreadCrumbModule } from '@progress/kendo-angular-navigation';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { BrowserModule } from '@angular/platform-browser';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, BreadCrumbModule], // import BreadCrumb module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let BreadCrumbModule = class BreadCrumbModule {
};
BreadCrumbModule = __decorate([
    NgModule({
        declarations: [declarations$1],
        exports: [exportedModules$1],
        imports: [CommonModule, ResizeSensorModule]
    })
], BreadCrumbModule);

/**
 * Represents a template that defines the item content of the BottomNavigation.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoBottomNavigationItemTemplate` directive inside the `<kendo-bottomnavigation>` tag ([see example]({% slug templates_bottomnavigation %})).
 */
let BottomNavigationItemTemplateDirective = class BottomNavigationItemTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
BottomNavigationItemTemplateDirective = __decorate([
    Directive({
        selector: '[kendoBottomNavigationItemTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], BottomNavigationItemTemplateDirective);

/**
 * @hidden
 */
let BottomNavigationItemComponent = class BottomNavigationItemComponent {
    get disabledClass() {
        return this.item.disabled;
    }
    get label() {
        return this.item.text ? this.item.text : null;
    }
    get tabindex() {
        return this.item.tabIndex ? this.item.tabIndex : 0;
    }
    get selectedClass() {
        return this.selectedIdx ? this.selectedIdx === this.index : this.item.selected;
    }
    get itemIcon() {
        return this.item.icon || this.item.iconClass ? true : false;
    }
    get iconClasses() {
        const kendoIcon = this.item.icon ? `k-icon k-i-${this.item.icon}` : '';
        const customIcon = this.item.iconClass ? this.item.iconClass : '';
        return `${kendoIcon} ${customIcon}`;
    }
};
__decorate([
    Input(),
    __metadata("design:type", BottomNavigationItemTemplateDirective)
], BottomNavigationItemComponent.prototype, "itemTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], BottomNavigationItemComponent.prototype, "item", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], BottomNavigationItemComponent.prototype, "index", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], BottomNavigationItemComponent.prototype, "disabledComponent", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], BottomNavigationItemComponent.prototype, "selectedIdx", void 0);
__decorate([
    HostBinding('attr.aria-disabled'),
    HostBinding('class.k-state-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], BottomNavigationItemComponent.prototype, "disabledClass", null);
__decorate([
    HostBinding('attr.aria-label'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], BottomNavigationItemComponent.prototype, "label", null);
__decorate([
    HostBinding('attr.tabindex'),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [])
], BottomNavigationItemComponent.prototype, "tabindex", null);
__decorate([
    HostBinding('attr.aria-selected'),
    HostBinding('class.k-state-selected'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], BottomNavigationItemComponent.prototype, "selectedClass", null);
BottomNavigationItemComponent = __decorate([
    Component({
        selector: '[kendoBottomNavigationItem]',
        template: `
        <ng-container *ngIf="!itemTemplate">
            <span *ngIf="itemIcon" class="k-bottom-nav-item-icon" [ngClass]="iconClasses"></span>
            <span *ngIf="item.text" class="k-bottom-nav-item-text">{{item.text}}</span>
        </ng-container>
        <ng-template *ngIf="itemTemplate"
            [ngTemplateOutlet]="itemTemplate?.templateRef"
            [ngTemplateOutletContext]="{ $implicit: item }">
        </ng-template>
    `
    })
], BottomNavigationItemComponent);

/**
 * @hidden
 */
class PreventableEvent {
    /**
     * @hidden
     */
    constructor(args) {
        this.prevented = false;
        Object.assign(this, args);
    }
    /**
     * Prevents the default action for a specified event.
     * In this way, the source component suppresses
     * the built-in behavior that follows the event.
     */
    preventDefault() {
        this.prevented = true;
    }
    /**
     * Returns `true` if the event was prevented
     * by any of its subscribers.
     *
     * @returns `true` if the default action was prevented.
     * Otherwise, returns `false`.
     */
    isDefaultPrevented() {
        return this.prevented;
    }
}

/**
 * Arguments for the `select` event of the BottomNavigation.
 */
class BottomNavigationSelectEvent extends PreventableEvent {
}

/**
 * @hidden
 */
const BOTTOMNAVIGATION_ITEM_INDEX = 'data-kendo-bottomnavigation-index';
/**
 * @hidden
 */
const colors = ['primary', 'secondary', 'tertiary', 'info', 'success', 'warning', 'error', 'dark', 'light', 'inverse'];

/**
 * Represents the [Kendo UI BottomNavigation component for Angular]({% slug overview_bottomnavigation %}).
 *
 * @example
 * ```ts-no-run
 * _@Component({
 *      selector: 'my-app',
 *      template: `
 *          <kendo-bottomnavigation [items]="items"></kendo-bottomnavigation>
 *      `
 * })
 * class AppComponent {
 *      public items: Array<any> = [
 *          { text: 'Inbox', icon: 'email', selected: true },
 *          { text: 'Calendar', icon: 'calendar'},
 *          { text: 'Profile', icon: 'user'}
 *      ]
 * }
 * ```
 */
let BottomNavigationComponent = class BottomNavigationComponent {
    constructor(localization, hostElement, ngZone, changeDetector, renderer) {
        this.localization = localization;
        this.hostElement = hostElement;
        this.ngZone = ngZone;
        this.changeDetector = changeDetector;
        this.renderer = renderer;
        /**
         * Sets a top border to the BottomNavigation ([see example]({% slug appearance_bottomnavigation %})).
         *
         * @default false
         */
        this.border = false;
        /**
         * Disables the whole BottomNavigation.
         *
         * @default false
         */
        this.disabled = false;
        /**
         * Fires each time an item is selected. This event is preventable.
         */
        this.select = new EventEmitter();
        /**
         * @hidden
         */
        this.hostClass = true;
        /**
         * @hidden
         */
        this.role = 'navigation';
        this._fill = 'flat';
        this._itemFlow = 'vertical';
        this._positionMode = 'fixed';
        this._themeColor = 'primary';
        this._nativeHostElement = this.hostElement.nativeElement;
        this.subscriptions = new Subscription();
        this.rtl = false;
        this.dynamicRTLSubscription = this.localization.changes.subscribe(({ rtl }) => {
            this.rtl = rtl;
            this.direction = this.rtl ? 'rtl' : 'ltr';
        });
    }
    /**
     * The fill style of the BottomNavigation ([see example]({% slug appearance_bottomnavigation %})).
     *
     * * The possible values are:
     * * (Default) `flat`
     * * `solid`
     */
    set fill(fill) {
        this.renderer.removeClass(this._nativeHostElement, `k-bottom-nav-${this.fill}`);
        this._fill = fill === 'solid' ? 'solid' : 'flat';
        this.renderer.addClass(this._nativeHostElement, `k-bottom-nav-${this._fill}`);
    }
    get fill() {
        return this._fill;
    }
    /**
     * Specifies how the icon and text label are positioned in the BottomNavigation items ([see example]({% slug items_bottomnavigation %}#toc-itemflow)).
     *
     * The possible values are:
     * * (Default) `vertical` - Renders the text below the icon.
     * * `horizontal` - Renders the icon and the text on the same line.
     */
    set itemFlow(itemFlow) {
        this.renderer.removeClass(this._nativeHostElement, `k-bottom-nav-item-flow-${this.itemFlow}`);
        this._itemFlow = itemFlow === 'horizontal' ? 'horizontal' : 'vertical';
        this.renderer.addClass(this._nativeHostElement, `k-bottom-nav-item-flow-${this._itemFlow}`);
    }
    get itemFlow() {
        return this._itemFlow;
    }
    /**
     * Specifies the position and behavior of the BottomNavigation when the page is scrollable ([see example]({% slug positioning_bottomnavigation %})).
     *
     * The possible values are:
     * * (Default) `fixed` - The BottomNavigation always stays at the bottom of the viewport, regardless of the page scroll position.
     * * `sticky` - Positions the BottomNavigation at the end of the scrollable container.
     */
    set positionMode(positionMode) {
        this.renderer.removeClass(this._nativeHostElement, `k-pos-${this.positionMode}`);
        this._positionMode = positionMode === 'sticky' ? 'sticky' : 'fixed';
        this.renderer.addClass(this._nativeHostElement, `k-pos-${this._positionMode}`);
    }
    get positionMode() {
        return this._positionMode;
    }
    /**
     * Specifies the theme color of the BottomNavigation ([see example]({% slug appearance_bottomnavigation %})).
     *
     * * The possible values are:
     * * (Default) `primary` - Applies coloring based on the primary theme color.
     * * `secondary` - Applies coloring based on the secondary theme color.
     * * `tertiary` - Applies coloring based on the tertiary theme color.
     * * `info` - Applies coloring based on the info theme color.
     * * `success` - Applies coloring based on the success theme color.
     * * `warning` - Applies coloring based on the warning theme color.
     * * `error` - Applies coloring based on the error theme color.
     * * `dark` - Applies coloring based on the dark theme color.
     * * `light` - Applies coloring based on the light theme color.
     * * `inverse` - Applies coloring based on the inverted theme color.
     */
    set themeColor(themeColor) {
        const newColor = colors.find(color => color === themeColor);
        if (newColor) {
            this.renderer.removeClass(this._nativeHostElement, `k-bottom-nav-${this._themeColor}`);
            this._themeColor = themeColor;
            this.renderer.addClass(this._nativeHostElement, `k-bottom-nav-${this._themeColor}`);
        }
    }
    get themeColor() {
        return this._themeColor;
    }
    /**
     * @hidden
     */
    get borderClass() {
        return this.border;
    }
    /**
     * @hidden
     */
    get disabledClass() {
        return this.disabled;
    }
    /**
     * @hidden
     */
    ngOnInit() {
        this.initDomEvents();
    }
    /**
     * @hidden
     */
    ngAfterViewInit() {
        this.applyClasses();
    }
    /**
     * @hidden
     */
    ngOnDestroy() {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
        this.subscriptions.unsubscribe();
    }
    /**
     * @hidden
     */
    selectItem(idx, args) {
        const eventArgs = new BottomNavigationSelectEvent(Object.assign({}, args));
        this.select.emit(eventArgs);
        if (!eventArgs.isDefaultPrevented()) {
            this.selectedIdx = idx;
        }
    }
    applyClasses() {
        this.renderer.addClass(this._nativeHostElement, `k-bottom-nav-${this.fill}`);
        this.renderer.addClass(this._nativeHostElement, `k-bottom-nav-item-flow-${this.itemFlow}`);
        this.renderer.addClass(this._nativeHostElement, `k-pos-${this.positionMode}`);
        this.renderer.addClass(this._nativeHostElement, `k-bottom-nav-${this.themeColor}`);
    }
    initDomEvents() {
        if (!this.hostElement) {
            return;
        }
        this.ngZone.runOutsideAngular(() => {
            this.subscriptions.add(this.renderer.listen(this._nativeHostElement, 'click', this.clickHandler.bind(this)));
            this.subscriptions.add(this.renderer.listen(this._nativeHostElement, 'keydown', this.keyDownHandler.bind(this)));
        });
    }
    clickHandler(e) {
        const itemIdx = this.getBottomNavigationItemIndex(e.target);
        const item = this.items[itemIdx];
        if (!item) {
            return;
        }
        if (item.disabled) {
            e.preventDefault();
            return;
        }
        const args = {
            index: itemIdx,
            item: item,
            originalEvent: e,
            sender: this
        };
        this.ngZone.run(() => {
            this.selectItem(itemIdx, args);
            this.changeDetector.markForCheck();
        });
    }
    keyDownHandler(e) {
        const isEnterOrSpace = e.keyCode === Keys.Enter || e.keyCode === Keys.Space;
        if (!isEnterOrSpace) {
            return;
        }
        this.clickHandler(e);
    }
    getBottomNavigationItemIndex(target) {
        const item = closestItem(target, BOTTOMNAVIGATION_ITEM_INDEX, this._nativeHostElement);
        if (item) {
            return itemIndex(item, BOTTOMNAVIGATION_ITEM_INDEX);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], BottomNavigationComponent.prototype, "items", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], BottomNavigationComponent.prototype, "border", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], BottomNavigationComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], BottomNavigationComponent.prototype, "fill", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], BottomNavigationComponent.prototype, "itemFlow", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], BottomNavigationComponent.prototype, "positionMode", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], BottomNavigationComponent.prototype, "themeColor", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], BottomNavigationComponent.prototype, "select", void 0);
__decorate([
    HostBinding('class.k-bottom-nav'),
    __metadata("design:type", Boolean)
], BottomNavigationComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('class.k-bottom-nav-border'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], BottomNavigationComponent.prototype, "borderClass", null);
__decorate([
    HostBinding('class.k-state-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], BottomNavigationComponent.prototype, "disabledClass", null);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String)
], BottomNavigationComponent.prototype, "role", void 0);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], BottomNavigationComponent.prototype, "direction", void 0);
__decorate([
    ContentChild(BottomNavigationItemTemplateDirective, { static: false }),
    __metadata("design:type", BottomNavigationItemTemplateDirective)
], BottomNavigationComponent.prototype, "itemTemplate", void 0);
BottomNavigationComponent = __decorate([
    Component({
        exportAs: 'kendoBottomNavigation',
        selector: 'kendo-bottomnavigation',
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.bottomnavigation'
            }
        ],
        template: `
        <ng-container *ngIf="items">
            <span kendoBottomNavigationItem
                *ngFor="let item of items; let idx=index"
                role="link"
                class="k-bottom-nav-item"
                [disabledComponent]="disabled"
                [item]="item"
                [index]="idx"
                [selectedIdx]="selectedIdx"
                [itemTemplate]="itemTemplate"
                [attr.${BOTTOMNAVIGATION_ITEM_INDEX}]="idx"
                [ngClass]="item.cssClass"
                [ngStyle]="item.cssStyle">
            </span>
        </ng-container>
    `
    }),
    __metadata("design:paramtypes", [LocalizationService,
        ElementRef,
        NgZone,
        ChangeDetectorRef,
        Renderer2])
], BottomNavigationComponent);

const templateDirectives$1 = [BottomNavigationItemTemplateDirective];
const exportedComponents = [BottomNavigationComponent, ...templateDirectives$1];
const declarations$2 = [BottomNavigationItemComponent, ...exportedComponents];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the BottomNavigation component.
 * @example
 *
 * ```ts-no-run
 * // Import the BottomNavigation module
 * import { BottomNavigationModule } from '@progress/kendo-angular-navigation';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { BrowserModule } from '@angular/platform-browser';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, BottomNavigationModule], // import BottomNavigation module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let BottomNavigationModule = class BottomNavigationModule {
};
BottomNavigationModule = __decorate([
    NgModule({
        declarations: [declarations$2],
        exports: [exportedComponents],
        imports: [CommonModule]
    })
], BottomNavigationModule);

/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Navigation components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Navigation module
 * import { NavigationModule } from '@progress/kendo-angular-navigation';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, NavigationModule], // import Navigation module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let NavigationModule = class NavigationModule {
};
NavigationModule = __decorate([
    NgModule({
        exports: [
            AppBarModule,
            BreadCrumbModule,
            BottomNavigationModule
        ]
    })
], NavigationModule);

// AppBar exports

/**
 * Generated bundle index. Do not edit.
 */

export { BottomNavigationItemComponent, BOTTOMNAVIGATION_ITEM_INDEX, BreadCrumbItemComponent, BreadCrumbListComponent, BREADCRUMB_ITEM_INDEX, BreadCrumbSeparatorDirective, PreventableEvent, AppBarComponent, AppBarSectionComponent, AppBarSpacerComponent, AppBarModule, NavigationModule, BreadCrumbComponent, BreadCrumbItemTemplateDirective, BreadCrumbModule, BottomNavigationComponent, BottomNavigationItemTemplateDirective, BottomNavigationSelectEvent, BottomNavigationModule };
